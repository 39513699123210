<template>
  <div id="footer" class="app-footer">
    &copy; {{ year }} Parking Management Company
  </div>
</template>

<script lang="ts">
export default {
  name: "AppFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
