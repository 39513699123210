<template>
  <!-- menu with submenu -->
  <div
    v-if="menu.children?.length"
    class="menu-item has-sub"
    :class="{ active: subIsActive(menu.children) }"
  >
    <a class="menu-link">
      <span class="menu-icon" v-if="menu.icon">
        <i :class="menu.icon"></i>
        <span
          class="w-5px h-5px rounded-3 bg-theme position-absolute top-0 end-0 mt-3px me-3px"
          v-if="menu.highlight"
        ></span>
      </span>
      <span class="menu-text">{{ menu.text }}</span>
      <span class="menu-caret" v-if="menu.children"><b class="caret"></b></span>
    </a>
    <div class="menu-submenu">
      <SidebarNav
        v-for="(submenu, i) in menu.children"
        :key="`submenu-${i}`"
        :menu="submenu"
      ></SidebarNav>
    </div>
  </div>

  <!-- menu without submenu -->
  <router-link
    v-else
    custom
    :to="menu.url ?? ''"
    v-slot="{ navigate, href, isActive }"
  >
    <div class="menu-item" :class="{ active: isActive }">
      <a :href="href" @click="navigate" class="menu-link">
        <span class="menu-icon" v-if="menu.icon">
          <i :class="menu.icon"></i>
          <span v-if="menu.label" class="menu-icon-label">{{
            menu.label
          }}</span>
        </span>
        <span class="menu-text">{{ menu.text }}</span>
      </a>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useRouter, RouterLink } from "vue-router";
import SidebarNav from "@/components/app/SidebarNav.vue";

defineProps<{
  menu: SidebarMenuItem;
}>();

function subIsActive(urls: SidebarMenuItem[]) {
  const currentRoute = useRouter().currentRoute.value.path;

  let match = false;

  for (let i = 0; i < urls.length; i++) {
    if (currentRoute.indexOf(urls?.[i]?.url ?? "") === 0) {
      match = true;
    }
  }

  return match;
}
</script>
