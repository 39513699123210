import { defineStore } from "pinia";
import { useAppSearchStore } from "@/stores/app-search";
import usStatesList from "@/assets/json/us-states-list.json";

export const useAppDataStore = defineStore({
  id: "appData",
  state: (): AppDataStoreState => {
    return {
      locations: [],
      partners: new Map(),
    };
  },
  getters: {
    usStates() {
      return usStatesList;
    },
  },
  actions: {
    updatePartner(partner: PMCPartner) {
      const appSearchStore = useAppSearchStore();

      this.partners.set(partner.partnerId, partner);
      appSearchStore.setResults("partners", Array.from(this.partners.values()));
    },
  },
});
