<template>
  <a
    href="/logout"
    class="dropdown-item d-flex align-items-center"
    @click.prevent="signOut"
    >Log Out <i class="fa fa-toggle-off fa-fw ms-auto text-gray-400 fs-16px"></i
  ></a>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  data() {
    return {
      isSigningOut: false,
    };
  },
  methods: {
    async signOut() {
      try {
        this.isSigningOut = true;
        await Auth.signOut();
      } catch (error) {
        this.isSigningOut = false;
        console.error("error signing out: ", error);
      }
    },
  },
};
</script>
