import axios from "axios";
import { Auth } from "aws-amplify";
const STAGE = import.meta.env.VITE_APP_STAGE;

export default function useApi() {
  // private helper to get the bearer token from cognito
  const getJwtToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getIdToken().getJwtToken();
    } catch (e) {
      return "";
    }
  };

  // create a new axios client
  const client = axios.create({
    baseURL: `https://${STAGE}.parking.api.parkingmgt.com/v2/`,
  });

  // add the bearer token before each request
  client.interceptors.request.use(async (config) => {
    const token = await getJwtToken();
    if (!token) return config;

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  return client;
}
