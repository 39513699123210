import { defineStore } from "pinia";

export const useAppSearchStore = defineStore({
  id: "appSearch",
  state: () => ({
    filters: {},
    results: {},
  }),
  actions: {
    setFilters(key: string, filters: { [k: string]: any }) {
      this.filters = { ...this.filters, [key]: filters };
    },
    setResults(key: string, results: { [k: string]: any }) {
      this.results = { ...this.results, [key]: results };
    },
    addItemToResults(key: string, item: any) {
      this.results[key].push(item);
    },
    updateItem(key: string, item: any, compareKey: string) {
      if (this.results[key]) {
        this.results[key] = this.results[key].map((i) => {
          if (i[compareKey] && i[compareKey] == item[compareKey]) {
            return item;
          }
          return i;
        });
      }
    },
  },
  getters: {
    getFilters: (state) => (key: string) => state.filters[key],
    getResults: (state) => (key: string) => state.results[key],
  },
});
