import axios from "axios";
import { Auth } from "aws-amplify";

const { VITE_BFF_BASE_URL } = import.meta.env;

export default function useApi() {
  const getJwtToken = async () => {
    try {
      const session = await Auth.currentSession();

      return session.getIdToken().getJwtToken();
    } catch (e) {
      return "";
    }
  };

  const client = axios.create({
    baseURL: `${VITE_BFF_BASE_URL}`,
  });

  client.interceptors.request.use(async (config) => {
    const token = await getJwtToken();

    if (!token) {
      return config;
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  });

  return client;
}
