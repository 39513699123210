<template>
  <div id="header" class="app-header">
    <!-- STAGE banner -->
    <div class="sandbox-banner bg-warning">
      <em>You're using the sandbox</em>
    </div>

    <div class="inner">
      <!-- BEGIN mobile-toggler -->
      <div class="mobile-toggler">
        <button
          type="button"
          class="menu-toggler"
          v-on:click="toggleAppSidebarMobileToggled"
        >
          <span class="bar"></span>
          <span class="bar"></span>
        </button>
      </div>
      <!-- END mobile-toggler -->

      <!-- BEGIN brand -->
      <div class="brand">
        <div class="desktop-toggler">
          <button
            type="button"
            class="menu-toggler"
            v-on:click="toggleAppSidebarMinify"
          >
            <span class="bar"></span>
            <span class="bar"></span>
          </button>
        </div>

        <a href="#" class="brand-logo">
          <img src="/assets/img/logo.png" alt="" height="40" />
        </a>
      </div>
      <!-- END brand -->

      <!-- BEGIN menu -->
      <div class="menu">
        <div class="menu-search"></div>
        <div class="menu-item dropdown">
          <a
            href="#"
            data-bs-toggle="dropdown"
            data-display="static"
            class="menu-link"
          >
            <div class="menu-text">{{ appUser?.name }}</div>
          </a>
          <div class="dropdown-menu dropdown-menu-end me-lg-3">
            <a
              v-if="canViewMenu"
              :href="sandboxUrl"
              target="_blank"
              class="dropdown-item d-flex align-items-center"
            >
              Use Sandbox
              <i
                class="fa fa-up-right-from-square fa-fw ms-auto text-gray-400 fs-16px"
              ></i>
            </a>
            <div class="dropdown-divider"></div>
            <a
              v-if="canViewMenu"
              :href="`${supportUrl}/latest-updates`"
              target="_blank"
              class="dropdown-item d-flex align-items-center"
            >
              Latest Updates
              <i
                class="fa fa-up-right-from-square fa-fw ms-auto text-gray-400 fs-16px"
              ></i>
            </a>
            <a
              v-if="canViewMenu"
              :href="`${supportUrl}/get-help/submit-a-ticket`"
              target="_blank"
              class="dropdown-item d-flex align-items-center"
            >
              Get Support
              <i
                class="fa fa-up-right-from-square fa-fw ms-auto text-gray-400 fs-16px"
              ></i>
            </a>
            <div class="dropdown-divider"></div>
            <SignoutButton />
          </div>
        </div>
      </div>
      <!-- END menu -->
    </div>
  </div>
</template>

<script lang="ts">
import { useAppOptionStore } from "@/stores/app-option";
import { useAppUserStore } from "@/stores/app-user";
import SignoutButton from "@/components/app/SignoutButton.vue";
const SUPPORT_URL = import.meta.env.VITE_SUPPORT_URL;
const SANDBOX_URL = import.meta.env.VITE_SANDBOX_URL;

export default {
  name: "AppHeader",
  setup() {
    return {
      appOption: useAppOptionStore(),
      appUser: useAppUserStore(),
      supportUrl: SUPPORT_URL,
      sandboxUrl: SANDBOX_URL,
    };
  },
  components: {
    SignoutButton,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    canViewMenu() {
      return true;
    },
  },
  methods: {
    toggleAppSidebarMinify() {
      this.appOption.appSidebarMinified = !this.appOption.appSidebarMinified;
    },
    toggleAppSidebarMobileToggled() {
      this.appOption.appSidebarMobileToggled =
        !this.appOption.appSidebarMobileToggled;
    },
  },
};
</script>
